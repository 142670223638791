<template>
  <div>
    <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
    <b-card>
      <b-row>
        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">Nome Fantasia</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{companyShow.fantasy_name}}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="AtSignIcon" class="mr-75" />
                <span class="font-weight-bold">Nome Social</span>
              </th>
              <td class="pb-50">
                {{companyShow.social_name == null ? "Não Informado" : companyShow.social_name}}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CreditCardIcon" class="mr-75" />
                <span class="font-weight-bold">CPF / CNPJ</span>
              </th>
              <td class="pb-50">
                {{companyShow.cpf_cnpj  == null ? "Não Informado" : companyShow.cpf_cnpj}}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">Telefone</span>
              </th>
              <td class="pb-50">
                {{companyShow.whatsapp}}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">Desde</span>
              </th>
              <td>
                {{companyShow.created_at | dateFormat}}
              </td>
            </tr>
          </table>
        </b-col>
        <!-- Endereço -->
        <b-col cols="12" xl="6" v-if="companyShow.address">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="HomeIcon" class="mr-75" />
                <span class="font-weight-bold">Rua e Numero</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{companyShow.address.street}} - Nº  {{companyShow.address.number}}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="FlagIcon" class="mr-75" />
                <span class="font-weight-bold">CEP</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{companyShow.address.zip_address}}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="PlusIcon" class="mr-75" />
                <span class="font-weight-bold">Complemento</span>
              </th>
              <td class="pb-50">{{companyShow.address.complement  == null ? "Não Informado" : companyShow.address.complement}}</td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CompassIcon" class="mr-75" />
                <span class="font-weight-bold">Cidade</span>
              </th>
              <td class="pb-50">{{companyShow.address.city}}</td>
            </tr>
            <tr>
              <th>
                <feather-icon icon="NavigationIcon" class="mr-75" />
                <span class="font-weight-bold">Estado</span>
              </th>
              <td>{{companyShow.address.state}}</td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <hr>
      <b-button variant="relief-primary" size="sm" @click="updateData(null)" v-b-toggle.sidebar-create>
        Adicionar catálogo
        <feather-icon icon="PlusIcon" />
      </b-button>
    </b-card>
    </b-overlay>
    <div>
      <h2 class="text-center">Catálogo</h2>
      <b-row>
        <div :key="indextr" v-for="(tr, indextr) in companyShow.company_details" class="col-4">
          <b-card
            :img-src="tr.image"
            img-alt="card img"
            img-width="150" img-height="250"
            img-top
            :title="tr.title"
            class="position-static"
          >
            <b-card-text>
              <b-row class="mb-1">
                <b-col>
                  <b-button variant="relief-primary" size="sm" block @click="updateData(tr)" v-b-toggle.sidebar-create>
                    <feather-icon icon="EditIcon" />
                    Editar catálogo
                  </b-button>
                </b-col>
                <b-col>
                  <b-button variant="relief-danger" size="sm" block @click="deleteData(tr)" v-b-modal.modal-delete>
                    <feather-icon icon="Trash2Icon" />
                    Apagar catálogo
                  </b-button>
                </b-col>
              </b-row>
              <small class="text-muted" v-if="tr.updated_at == tr.created_at">Criado em {{tr.created_at | dateFormat}}</small>
              <small class="text-muted" v-else>Editado em {{tr.updated_at | dateFormat}}</small>
            </b-card-text>
          </b-card>
        </div>
      </b-row>
    </div>
    <create-edit :dataProp="sidebarData" @fetch-details="fetchDetails" />
    <modal-delete :dataProp="sidebarDataDelete" @fetch-details="fetchDetails" />
  </div>
</template>
<script>
import {
  BCard, BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody,
} from 'bootstrap-vue'
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import CreateEdit from "./CreateEdit";
import ModalDelete from "./DeleteModal";

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    CreateEdit,
    ModalDelete
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: true,
      sidebarData: {},
      sidebarDataDelete: {},
    }
  },
  computed: {
    ...mapState("companies", ["companyShow"]),
  },
  methods: {
    ...mapActions("companies", ["fetchDetails"]),

    updateData(val){
      this.sidebarData = {
        id: val.id,
        title: val.title,
      }
    },
    deleteData(val){
      this.sidebarDataDelete = {
        id: val.id,
        title: val.title,
      }
    },
  },
  created(){
    this.fetchDetails({
      companie_id: this.$route.params.id_company,
    });
  },
  watch: {
    companyShow(){
      this.isLoading = false
    }
  }
}
</script>