<template>
  <b-modal
    id="modal-delete"
    ok-variant="danger"
    modal-class="modal-danger"  
    ref="modal-delete"
    centered
    title="Deletar"
  >
    <b-card-text>
      <p>Você está preste a deletar este produto do catálago: <b>{{dataDelete.title}}</b></p>
      Por favor, confirme a exclusão antes de prosseguir.
      <b-form-checkbox
        id="checkbox-1"
        @change="newDeleteStatus"
        v-model="DeleteStatus"
        name="checkbox-1"
        value="accepted"
        unchecked-value="null"
      >
        Concordo em excluir esta fase
      </b-form-checkbox>  
    </b-card-text>
    <template #modal-footer>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mt-2"
          variant="outline-danger"
          block
          :disabled="DeleteStatus == null"
          @click="deleteExclusive()"
        >
          Deletar
        </b-button>
      </b-col>
    </template>
  </b-modal>
</template>
<script>
import {BCard} from 'bootstrap-vue'
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BCard
  },
  directives: {
    Ripple,
  },
  props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      DeleteStatus: null,
      dataDelete: {
        id: null,
        title: null
      },
    };
  },
  computed: {
    ...mapState("companyDetails", ["companyDetails"]),
  },
  methods: {

    newDeleteStatus(value){
      this.DeleteStatus = value
    },

    deleteExclusive(){
      this.$store
        .dispatch("companyDetails/delete", this.dataDelete.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
          this.DeleteStatus = null
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$refs['modal-delete'].hide()
          this.$emit("fetch-details", 
          {
            companie_id: this.$route.params.id_company,
          });
        })
    },
  },
  watch: {
    dataProp: {
      immediate: true, 
      handler (val, oldVal) {
        if (!val) return;
        this.dataDelete.id = this.dataProp.id
        this.dataDelete.title = this.dataProp.title
      }
    }
  },
};
</script>
